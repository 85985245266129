<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div v-if="availability">
      <router-link :to="{ name: 'settings-home' }" icon='arrowdownlarge' class="is-back"><p><i class="backarrow"></i>BACK</p></router-link>
      <h1>Opening Hours</h1>
      <p>Set your standard business hours. These times will be used for your appointment calendar.</p>
      <hr>
      <b-table
        :data="days"
        :striped="false"
        :mobile-cards="true"
        class="is-expanded"
      >
        <template slot-scope="props">
          <b-table-column field="name" label="Day" class="table-column">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="start" label="Start" width="150" class="table-column">
            <b-timepicker v-model="availability[props.row.index].start" :incrementMinutes="15" hourFormat="12"
                          placeholder="Opening Time" :disabled="availability[props.row.index].closed"/>
          </b-table-column>
          <b-table-column width="10" class="table-column">
          <h6>TO</h6>
          </b-table-column>
          <b-table-column field="end" label="End" width="150" class="table-column">
            <b-timepicker v-model="availability[props.row.index].end" :incrementMinutes="15" hourFormat="12"
                          placeholder="Closing Time" :disabled="availability[props.row.index].closed"/>
          </b-table-column>
          <b-table-column field="closed" label="Closed" width="80" class="table-column">
            <b-switch v-model="availability[props.row.index].closed"></b-switch>
          </b-table-column>
        </template>
      </b-table>
      <hr>
      <div class="level is-mobile">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <a class="button is-light" @click="cancel">Cancel</a>
          </div>
          <div class="level-item">
            <button class="button is-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'Availability',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Opening Hours', route: null }
      ],
      isLoading: false,
      availability: null,
      days: [
        {
          index: 'sunday',
          name: 'Sunday',
          start: null,
          end: null,
          closed: null
        },
        {
          index: 'monday',
          name: 'Monday',
          start: null,
          end: null,
          closed: null
        },
        {
          index: 'tuesday',
          name: 'Tuesday',
          start: null,
          end: null,
          closed: null
        },
        {
          index: 'wednesday',
          name: 'Wednesday',
          start: null,
          end: null,
          closed: null
        },
        {
          index: 'thursday',
          name: 'Thursday',
          start: null,
          end: null,
          closed: null
        },
        {
          index: 'friday',
          name: 'Friday',
          start: null,
          end: null,
          closed: null
        },
        {
          index: 'saturday',
          name: 'Saturday',
          start: null,
          end: null,
          closed: null
        }
      ]
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    reloadAvailability () {
      this.isLoading = true
      this.$store.dispatch('settings/fetchAvailability')
        .then((availability) => {
          this.availability = availability
          this.isLoading = false
        })
    },
    save () {
      this.$store.dispatch('settings/saveAvailability', this.availability)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Availability saved.',
            type: 'is-success'
          })
          this.$router.push({ name: 'settings-home' })
        })
        .catch(err => {
          const errors = err.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 4000,
                queue: false
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${err.message}`,
              type: 'is-danger',
              duration: 4000
            })
          }
        })
    }
  },
  mounted () {
    this.reloadAvailability()
  },
  components: { Breadcrumbs }
}
</script>

<style lang="scss" scoped>

//Marc Aligning left mobile quote invoices top
@media screen and (max-width: 768px) {

.b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td {
    //background-color: crimson !important;
    padding-left: 10px;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0px solid whitesmoke;
  }
.table td, .table th {
    border: 1px solid #ccc;
    border-width: 1px;
    padding: 10px 0px 10px;
    //align-items: flex-start;
}

tr > td:last-of-type {
    margin-bottom: 30px !important;
    margin-top: 10px !important;
    background-color: #FAFAFA !important;
    padding: 10px 10px;
}
.switch .control-label {
    opacity: 0%;
    //background-color: crimson;
}

}
</style>
