<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div v-if="account">
      <router-link :to="{ name: 'settings-home' }" icon='arrowdownlarge' class="is-back"><p><i class="backarrow"></i>BACK</p></router-link>
      <h1>Account Details</h1>
      <p>Set your standard business hours. These times will be used for your appointment calendar.</p>
      <hr>
      <b-field class="modal-label" label="Your Business Name">
        <b-input v-model="account.name" type="text" placeholder="Burleigh Heads, Gold Coast"/>
      </b-field>
      <div class="columns">
        <div class="column">
          <b-field label="Business Number" class="modal-label" expanded>
            <b-input v-model="account.businessRegistrationNumber" type="text" placeholder=""/>
          </b-field>
        </div>
        <div class="column">
          <b-field class="modal-label" label="Country">
            <b-select placeholder="Select country" v-model="account.countryId">
              <option
                v-for="country in countries"
                :value="country.id"
                :key="country.id">
                {{ country.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="notification is-info" v-if="isUnitedStates">
            In order to use Bunya in the <b>United States</b> you need to configure your sales tax rates. Depending on where your business operates, where your customers are, and your specific financial situation these rates may change.
            Sales taxes can apply at a county, city, and state level. <b>Speak to your accountant</b> about which sales taxes you are required to collect then <a @click="goToSalesTaxes">configure you sales tax rates</a> in Bunya.
          </div>
        </div>
      </div>
      <hr>
      <b-field class="modal-label" label="Introduction" message="A short introduction describing your business.">
        <b-input v-model="account.introduction" type="textarea" placeholder="E.g. XYZ Electrical has been installing television antennas since 1985. We provide quality products with friendly, reliable service."/>
      </b-field>
      <hr>

      <div class="columns">
        <div class="column">
          <b-field label="Mobile Phone" expanded class="modal-label">
            <b-input v-model="account.phone" type="tel" placeholder="0400 123 456"/>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Email" expanded class="modal-label">
            <b-input v-model="account.email" type="email" placeholder="mail@example.com"/>
          </b-field>
        </div>
      </div>
      <hr>
      <div class="columns">
        <div class="column">
          <b-field label="Website" expanded class="modal-label">
            <b-input v-model="account.domain" type="text" placeholder="www.website.com"/>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Facebook" expanded class="modal-label">
            <b-input v-model="account.facebook" type="text" placeholder="YourFacebookProfileName"/>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Instagram" expanded class="modal-label">
            <b-input v-model="account.instagram" type="text" placeholder="your.instagram.username"/>
          </b-field>
        </div>
      </div>
      <hr>
      <b-field class="modal-label" label="Street Address">
        <b-input v-model="account.address" type="textarea" placeholder="Burleigh Heads, Gold Coast"/>
      </b-field>
      <hr>
      <div class="level is-mobile">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <a class="button is-light" @click="cancel">Cancel</a>
          </div>
          <div class="level-item">
            <button class="button is-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'AccountDetails',
  computed: {
    countries () {
      return this.$store.getters['settings/countries']
    },
    isUnitedStates () {
      if (!this.account.countryId) {
        return false
      }
      const unitedStatesCountryCode = 'US'
      const countryId = this.account.countryId
      const countries = this.countries.filter((country) => {
        return country.id === countryId
      })
      if (!countries.length) {
        return false
      }
      return countries[0].code === unitedStatesCountryCode
    }
  },
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Account Details', route: null }
      ],
      isLoading: false,
      account: false
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    goToSalesTaxes () {
      this.$router.push({ name: 'settings-tax-rate-groups' })
    },
    reloadAccount () {
      this.isLoading = true
      this.$store.dispatch('settings/fetchAccount')
        .then((account) => {
          this.account = account
          this.isLoading = false
        })
    },
    save () {
      this.$store.dispatch('settings/saveAccount', this.account)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Account settings saved.',
            type: 'is-success'
          })
          this.$router.go()
        })
        .catch(err => {
          const errors = err.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 4000,
                queue: false
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${err.message}`,
              type: 'is-danger',
              duration: 4000
            })
          }
        })
    }
  },
  mounted () {
    this.reloadAccount()
    this.$store.dispatch('settings/fetchCountries')
  },
  components: { Breadcrumbs }
}
</script>
